<template>
  <div>
    <dashboard-page-title
      :showAddBtn="false"
      :showFilter="false"
      :showSearch="false"
      :showExportation="false"
      >طلبات السحب</dashboard-page-title
    >

    <main-table
      :fields="fields"
      list_url="admin/wallets/withdrawal-requests"
      additionalUrl="&model_type=doctor&model_type=clinic&status=pending"
      :baseURL="VUE_APP_WEBSITE_LINK"
    ></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_WEBSITE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_WEBSITE_LINK,
      fields: [
        { label: 'Id', key: 'id', class: 'text-right', sortable: true },
        { label: 'الفاتورة', key: 'invoice', class: 'text-right', type: 'image' },
        { label: 'اسم العميل', key: 'customer_name', class: 'text-right' },
        {
          label: 'الحالات',
          key: 'status',
          class: 'text-right',
          type: 'status'
        },
        { label: 'المصاريف', key: 'fee', class: 'text-right' },
        { label: 'الكمية', key: 'amount', class: 'text-right' },
        { label: 'نوع النموذج', key: 'model_type', class: 'text-right' },
        { label: 'الملاحظات', key: 'notes', class: 'text-right' },
        {
          label: 'model_type',
          key: 'model_type',
          class: 'text-right'
        },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'show',
              icon: 'ri-eye-fill',
              color: 'primary',
              ifNavigate: true,
              routePath: 'show-withdrawal-requests/:id'
            }
          ]
        }
      ]
    }
  },
  methods: {}
}
</script>
